<template>
	<div>
		<div style="margin-top: 40px" class="content_wrap">
			<div class="mypage_area_wrap">
				<div class="all_menu_wrap2 d_none_990">
					<div class="menu_section2">
						<div class="menu">
							<div class="menu_head">
								<div v-if="user_info_list" class="member_name">
									<span>{{ user_info_list.nickname }}</span
									>님
								</div>
								<div v-if="!user_info_list" class="member_name"><span>방문고객</span>님</div>
								<div v-if="user_info_list" class="member_category">
									고객님은
									<span style="color: lightgreen" v-if="store.membership == true">멤버쉽 </span
									><span style="color: red" v-else>비 멤버쉽</span>회원 입니다.
								</div>
								<div v-if="!user_info_list" class="member_category">
									서비스 이용을 위해 <span>로그인</span>을 해주세요.
								</div>
								<div v-if="user_info_list" class="menu_head_btm">
									<button @click="deleteAllCookies()" v-if="token_valid === true" type="button">
										로그아웃
									</button>
									<button
										@click="router.push('/mypage/account')"
										v-if="token_valid === true"
										type="button"
									>
										정보수정
									</button>
								</div>
								<div v-if="!user_info_list" class="menu_head_btm">
									<button
										@click="router.push('/login')"
										v-if="token_valid === false"
										type="button"
										style="margin-left: 0"
									>
										로그인
									</button>
								</div>
							</div>
							<div class="mypage_menu_wrap">
								<ul>
									<li>
										<router-link to="/mypage/like">좋아요 리스트</router-link>
									</li>
									<!-- <li>
										<a style="cursor: pointer" @click="router.push('/mypage/content')">콘텐츠 보관함</a>
									</li> -->
									<li>
										<router-link to="/mypage/membership">나의 요금제 / 회원권 결제</router-link>
										<!-- <a style="cursor: pointer" @click="router.push('')"></a> -->
									</li>
									<li>
										<router-link to="/mypage/playlist">나의 재생 리스트</router-link>
										<!-- <a style="cursor: pointer" @click="router.push('')"></a> -->
									</li>
									<li>
										<router-link to="/mypage/gift">쿠폰함</router-link>
										<!-- <a style="cursor: pointer" @click="router.push('')">쿠폰함</a> -->
									</li>
									<!-- <li>
									<a href="">성우 페이지<i class="bx bxs-chevron-up"></i></a>
								</li> -->
									<!--2depth 메뉴-->
									<!-- <ul class="menu_2depth">
									<li><a href="">성우 신청</a></li>
									<li><a href="">나의프로필</a></li>
									<li><a href="">나의 매칭 현황</a></li>
									<li><a href="">나의 작업 페이지</a></li>
									<li><a href="">정산 내역</a></li>
									<li><a href="">계약 내역</a></li>
									<li><a href="">작품 리스트 둘러보기</a></li>
								</ul>
								<li>
									<a href="">작가 페이지<i class="bx bxs-chevron-up"></i></a>
								</li> -->
									<!--2depth 메뉴-->
									<!-- <ul class="menu_2depth">
									<li><a href="">내 작품 리스트</a></li>
									<li><a href="">나의 매칭 현황</a></li>
									<li><a href="">나의 요청 작품 현황</a></li>
									<li><a href="">충전 내역</a></li>
									<li><a href="">지급 내역</a></li>
									<li><a href="">계약 내역</a></li>
									<li><a href="">성우 프로필 둘러보기</a></li>
								</ul> -->
								</ul>
							</div>
						</div>
					</div>
				</div>
				<router-view v-slot="{ Component, route }">
					<transition name="fade" mode="out-in" appear>
						<component :is="Component" :key="route.path" />
					</transition>
				</router-view>
			</div>
		</div>
		<component :is="store.Refund_modal ? RefundModal : null" />
	</div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { userInfoStore } from '@/store/UserStore';
import axios from 'axios';
import jwt from '@/api/LocalStorage';
import RefundModal from '@/components/mypage/RefundModal.vue';
import { storeToRefs } from 'pinia';
import { contentPlayerStore } from '@/store/contentDetailStore';

const naver_token = jwt.getToken('naver');
const token_valid = ref(false);
const user_info = ref('');
const store = userInfoStore();
const router = useRouter();
// const nickname = ref('');
const ID_TOKEN_KEY = 'id_token';
// const membership = ref(jwt.getToken('membership'));

// playerInfo store setting
// 마이페이지 관련 페이지 접속 시 재생 중이던 오디오 플레이어를 비활성화 함
const playerStore = contentPlayerStore();
const { playerVisible, playState } = storeToRefs(playerStore);
playerVisible.value = false;
playState.value = false;

// 유저 정보 담겨있음
const { user_info_list } = storeToRefs(store);
if (jwt.getToken(ID_TOKEN_KEY)) {
	token_valid.value = true;
} else if (!jwt.getToken(ID_TOKEN_KEY)) {
	token_valid.value = false;
}
// onMounted(() => {
// 	// if (jwt.getToken(ID_TOKEN_KEY)) {
// 	// 	store.user_info();
// 	// }
// 	// setTimeout(() => {
// 	// 	user_info.value = store.user_info_list;
// 	// 	if (store.user_info_list !== null) {
// 	// 		nickname.value = store.user_info_list.nickname;
// 	// 	}
// 	// 	if (jwt.getToken(ID_TOKEN_KEY)) {
// 	// 		token_valid.value = true;
// 	// 	} else if (!jwt.getToken(ID_TOKEN_KEY)) {
// 	// 		token_valid.value = false;
// 	// 	}
// 	// }, 100);
// });

const deleteAllCookies = () => {
	const del_local = () => {
		// router.replace('/');
		alert('로그아웃이 완료되었습니다.');
		window.location.href = '/';

		jwt.destroyToken(ID_TOKEN_KEY);
		jwt.destroyToken('id');
		jwt.destroyToken('nickname');
		jwt.destroyToken('userNo');
	};
	//카카오토큰 유무 확인
	if (window.Kakao.Auth.getAccessToken()) {
		//인증토큰을 로컬에서 삭제하는 메소드
		//이것만 사용시 연결이 끊기지 않고 바로 로그인과정 스킵해서 다시로그인됨
		window.Kakao.Auth.logout(function (data) {
			window.Kakao.Auth.getAccessToken(del_local());
		});
	} else if (jwt.getToken('naver')) {
		//네이버는 로그아웃처리 메소드가 따로없음
		//그래서 저 URL로 보내주면 쿠키에있는 네이버 로그인 데이터가 날아감
		//디스트로이드 토큰은 네이버 로그인시 임의로 만들어준 인증 로컬토큰
		// window.open('http://nid.naver.com/nidlogin.logout', '_blank');
		axios
			.get(
				`https://nid.naver.com/oauth2.0/token?grant_type=delete&client_id=TEA0Alw6wlCncJct7Oda&client_secret=pIT61g7nD7&access_token=${naver_token}&service_provider=NAVER`,
			)
			.then((res) => {
				console.log(res);
			});

		jwt.destroyToken('naver');
		del_local();
	} else {
		//일반로그인 로그아웃처리
		del_local();
	}
	token_valid.value = false;
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition-property: opacity;
	transition-duration: 0.2s;
}

.fade-enter-active {
	transition-delay: 0.2s;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
