<template>
	<div class="modal_wrap">
		<div class="modal modal_500">
			<div class="modal_head">
				<h2>구독 취소하기</h2>
				<i @click="store.Refund_modal = false" class="bx bx-x"></i>
			</div>
			<div class="mypage_tit_wrap">
				<h4>환불 정보</h4>
			</div>
			<div class="mypage_list_box">
				<ul class="tit_wrap2">
					<li class="bold line_h">결제 요금제</li>
					<li class="bold line_h">사용 기간</li>
					<li class="bold line_h">결제 금액</li>
					<li class="bold line_h">기간 사용금액</li>
					<li class="bold line_h">수수료</li>
					<li class="bold line_h">환불 금액</li>
				</ul>
				<ul v-if="refund_data !== null" class="info_wrap">
					<li class="line_h">{{ refund_data.item_name }}</li>
					<li class="line_h">{{ refund_data.charge_date }} ~ {{ refund_data.use_end_date }}</li>
					<li class="line_h">{{ refund_data.settle_amt }}원</li>
					<li class="line_h point_blue">- {{ refund_data.use_date_amount }}&nbsp;원</li>
					<li class="line_h point_blue">- {{ refund_data.fee }}&nbsp;원</li>
					<li class="line_h point_red">+ {{ refund_data.refund_amount }}&nbsp;원</li>
				</ul>
			</div>
			<div class="caution">
				<ul>
					<li>환불이 가능한 경우 환불이 진행되며, 10%의 환불 수수료가 공제됩니다.</li>
					<li>
						잔여 캐시가 10,000원 미만이면, 1,000원의 환불 수수료가 공제되며, 남은 금액이 1,000원 이하일 경우
						환불이 진행되지 않습니다.
					</li>
					<li>신용카드는 취소완료 후, 3~5일 내 카드사 승인이 취소가 됩니다.(영업일 기준)</li>
				</ul>
			</div>
			<div class="btn_wrap_line">
				<button @click="store.Refund_modal = false" type="button" class="btn_basic btn_grey_bg">취소</button>
				<button type="button" @click="use_plan_cancle()" class="btn_basic btn_green_bg">신청하기</button>
			</div>
		</div>
	</div>
</template>
<script setup>
import { userInfoStore } from '@/store/UserStore';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import ajax from '@/api/api-manager';

const refund_data = ref(null);
const store = userInfoStore();
const router = useRouter();

ajax('charge/use_plan').then((res) => {
	refund_data.value = res.data.data;
});

const use_plan_cancle = () => {
	ajax('charge/use_plan_cancle')
		.then((res) => {
			store.Refund_modal = false;
			alert('환불이 완료되었습니다 !');
			// router.replace('/');
			window.location.reload();
		})
		.catch((error) => {
			console.log(error.response);
			alert('환불 에러');
		});
};
</script>
